<template>
  <b-card title="">
    <b-tabs>
      <b-tab active  @click="$router.push('/add-user')">
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Add User</span>
        </template>

 <AddUser></AddUser>
      </b-tab>
      <b-tab @click="$router.push('/all-user-tab')" >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>All Users</span>
        </template>
        <router-view></router-view>

<!-- <ViewUsers></ViewUsers> -->
      </b-tab>
     
     
    </b-tabs>

  </b-card>
</template>

<script>

import { BTabs, BTab, BCardText,BCard } from 'bootstrap-vue'

import AddUser from './AddUser.vue';
import ViewUsers from './ViewUsers.vue';


export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,
  
    AddUser,
    ViewUsers
},
  data() {
    return {
   
    }
  },
}
</script>
