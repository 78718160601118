<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
 Add User

       <feather-icon icon="Edit2Icon" />
      </h4>
      
      
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 100%" @submit.prevent="save" ref="form">
        <b-row>
          
          <b-col md="6">
            <b-form-group
              label=" Name"
              label-for="blog-edit-title"
              class="mb-2"
            >
             <validation-provider
                  #default="{ errors }"
                  name=" Name"
                  rules="required"
                >
              <b-form-input id="blog-edit-title" v-model="addCourseForm.name" />
                 <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
         
          <b-col md="6">
            <b-form-group
              label="Email"
              label-for="blog-edit-slug"
              class="mb-2"
            >
            
               <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
              <b-form-input
                id="blog-edit-slug"
                v-model="addCourseForm.email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
                 <b-col md="6">
            <b-form-group
              label="password"
              label-for="blog-edit-title"
              class="mb-2"
            >
             <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
              <b-form-input id="blog-edit-title" v-model="addCourseForm.password" />
                 <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
         
         <b-col md="6">
            <b-form-group
              label="Role"
              label-for="blog-edit-category"
              class="mb-2"
            >
               <validation-provider
                  #default="{ errors }"
                  name="Role"
                  rules=""
                >
              <v-select
                id="blog-edit-category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="addCourseForm.role"
               
                :options="rolesList"
                
              />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
           
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from '@validations'
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import store from '@/store'
import Vue from "vue";
export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null);
      const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
     const refPreviewE2 = ref(null);
    const Tabimage = ref("");
    const rolesList=ref([])
 store.dispatch('setting/AllRoles')
        .then(response => {
          console.log("response",response.data.data)
          rolesList.value=response.data.data
        })
    const addCourseForm = reactive({
    name:'',
    password:'',
    role:'',
    email:''
    });
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
     const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
        refPreviewE2.value.src = base64;
      }
    );
     const addCourseFormvalidate=ref()
     const form=ref()

    const save = () => {
      
      console.log(addCourseForm)
      const formData = new FormData();

    
        
         addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append("email", addCourseForm.email);
formData.append("name", addCourseForm.name);
formData.append("role", addCourseForm.role);

formData.append("password", addCourseForm.password);

           store.dispatch('setting/addUser',formData)
        .then(response => {
               form.value.reset()
               addCourseForm.role=''
           Vue.swal({
                       title: "User Added ",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
          
        }).catch((error) => {
      
            Vue.swal({
              title: "",
              text: `${error.response.data.message}`,
              icon: "error",
              confirmButtonText: "ok",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
         
      console.log("alt_header_image", addCourseForm);
        }})
    };
    return {
      refInputEl,
      refPreviewEl,
      refInputE2,
       refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      rolesList,
     form,

      save,
       required, email
    };
  },

  components: {
    BCard,
    BMedia,
  ValidationProvider, ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
