<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="
              d-flex
              align-items-center
              justify-content-start
              mb-1 mb-md-0
            ">
            <label>Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              v-model="perPage"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
          </b-col>

          <!-- Search -->
        </b-row>
      </div>

      <b-table
        ref="refCourseListTable"
        class="position-relative"
        striped
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="categoriesList"
        show-empty
        empty-text="No matching records found">
        <!-- Column: Status -->

        <!-- Column: Actions -->

        <!-- Column: Actions -->

        <template #cell(actions)="data">
          <a>
            <router-link
              :to="`/update-user/${data.item.id}`"
              style="margin: 0px 12px">
              <feather-icon icon="EditIcon" />
            </router-link>
          </a>
          <a   v-if="$can('delete', 'user')" role="button" @click="DeleteUser(data.item.id)">
            <feather-icon icon="TrashIcon" />
          </a>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            ">
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            ">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BDropdownItemButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, watch, computed, reactive } from "@vue/composition-api";
import Vue from "vue";

// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {
    // useCousresList,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BDropdownItemButton,

    vSelect,
  },
  setup() {
    const categoriesList = ref([]);
    const perPageOptions = [25, 50, 100, 200];
    const perPage = ref(10);
    const totalCategories = ref(0);
    const currentPage = ref(1);
    const refCourseListTable = ref("");

    const tableColumns = [
      { key: "id", label: "Id", },
      { label: " Name", key: "name", },
      // { key: 'last_login_ip',key:'link_id', },
      { key: "last_login_at", label: "Last Login", },
      {
        key: "roles[0]",
        label: "Role"

        
      },

      { key: "actions" },
    ];
    watch([currentPage, perPage], () => {
      getCategories();
    });
    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value
        ? refCourseListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCategories.value,
      };
    });
    const meta = reactive({});
    const getCategories = () => {
      store
        .dispatch("setting/AllUser", {
          per_page: perPage.value,
          page: currentPage.value,
        })
        .then((response) => {
          console.log("response", response.data.data);
        
          categoriesList.value = response.data.data;
          totalCategories.value = response.data.meta.total;
        });
    };
    getCategories();
    const DeleteUser = (id) => {
      Vue.swal({
        icon: "warning",
        title: "Are You Sure?",
        confirmButtonText: "YES",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      }).then((result) => {
        if (result.value) {
          store.dispatch("setting/DeleteUser", id).then((response) => {
            getCategories();
            Vue.swal({
              title: "User Deleted ",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
        }
      });
    };

    return {
      tableColumns,
      categoriesList,
      DeleteUser,
      meta,
      getCategories,
      perPageOptions,
      totalCategories,
      currentPage,
      perPage,
      dataMeta,
      refCourseListTable,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
